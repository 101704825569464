import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateImg'
})
export class TruncateImgPipe implements PipeTransform {
  transform(htmlContent: string, ...args: unknown[]): unknown {
    const tmp = document.createElement('div');
    tmp.innerHTML = htmlContent;
    const img = tmp.getElementsByTagName('img')[0];

    if (!img) {
      return htmlContent;
    }

    tmp.removeChild(img);
    return tmp.innerHTML;
  }
}

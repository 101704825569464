import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';

import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import {
  provideAuth,
  initializeAuth,
  indexedDBLocalPersistence,
  browserPopupRedirectResolver
} from '@angular/fire/auth';
import { provideFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { FunctionsModule } from '@angular/fire/functions';
import { provideAppCheck, initializeAppCheck, ReCaptchaV3Provider, AppCheckModule } from '@angular/fire/app-check';
import { NgAisModule, NgAisRootModule } from 'angular-instantsearch';

import { ApplicationContext } from 'simplyfire/ngx';

import { environment } from 'environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SearchModule } from './components/search/search.module';
import { initializeFirestore } from 'firebase/firestore';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { FormsModule } from '@angular/forms';
import { AppCheckComponent } from './components/app-check/app-check.component';
import * as Sentry from '@sentry/angular';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  declarations: [AppComponent, AppCheckComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,
    LoggerModule.forRoot({ level: environment.logLevel }),

    // Firebase modules
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() =>
      initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver
      })
    ),
    provideFirestore(() => initializeFirestore(getApp(), { ignoreUndefinedProperties: true })),
    provideStorage(() => getStorage()),
    provideMessaging(() => getMessaging()),
    FunctionsModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),
    ServiceWorkerModule.register('ngsw-worker.js'),
    ToastModule,
    SearchModule.withConfig(environment.algoliaConfig),
    NgAisRootModule,
    NgAisModule.forRoot(),
    DialogModule,
    ButtonModule,
    TabViewModule,
    FormsModule,
    environment.useEmulators
      ? [AppCheckModule]
      : [
          provideAppCheck(() => {
            const provider = new ReCaptchaV3Provider(environment.recaptcha3SiteKey);
            return initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });
          })
        ]
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    MessageService,

    // Initialize Sentry error tracking on Prod mode
    ...(environment.production
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false
            })
          },
          {
            provide: Sentry.TraceService,
            deps: [Router]
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
          }
        ]
      : [])
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    ApplicationContext.injector = injector;
  }
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- Classy -->
<classy-layout
  *ngIf="layout === 'classy'"
  class="flex w-full lg:max-w-screen-lg md:max-w-screen-md mx-auto"
></classy-layout>

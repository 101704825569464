/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'app',
        // title   : 'App',
        // subtitle: 'Unique dashboard designs',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'app.debates',
                title: 'Article Debates',
                type : 'basic',
                icon : 'heroicons_outline:newspaper',
                link : '/debates',
                searchIndex: 'debate'
            },
            {
                id   : 'app.news',
                title: 'Article Feed',
                type : 'basic',
                icon : 'heroicons_outline:rss',
                link : '/news',
                searchIndex: 'article',
            },
            {
                id   : 'app.leaderboards',
                title: 'Leaderboards',
                type : 'basic',
                icon : 'heroicons_outline:presentation-chart-bar',
                link : '/leaderboards',
                authRequired: true,
            },
            {
                id   : 'app.notifications',
                title: 'Notifications',
                type : 'basic',
                icon : 'heroicons_outline:bell',
                link : '/notifications',
                authRequired: true,
            }
        ]
    }
];

import { Injectable } from '@angular/core';
import { quotes } from 'app/core/quotes';
import { BehaviorSubject } from 'rxjs';
import { FuseMediaWatcherService } from '../../@fuse/services/media-watcher';
import { UntilDestroy, untilDestroyed } from '@arbitral/common/app';

interface LayoutConfig {
  showToolbar: boolean;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  config$: BehaviorSubject<LayoutConfig>;
  globalSpin$: BehaviorSubject<{
    visible: boolean;
    tip?: string;
  }>;

  mobileLayout = false;

  constructor(private fuseMediaWatcher: FuseMediaWatcherService) {
    this.config$ = new BehaviorSubject({
      showToolbar: true
    });

    this.globalSpin$ = new BehaviorSubject({ visible: false });

    // Subscribe to media changes
    this.fuseMediaWatcher.onMediaChange$.pipe(untilDestroyed(this)).subscribe(({ matchingAliases }) => {
      // Set the drawerMode and drawerOpened
      this.mobileLayout = !(
        matchingAliases.includes('sm') ||
        matchingAliases.includes('md') ||
        matchingAliases.includes('lg')
      );
    });
  }

  isMobileLayout() {
    return this.mobileLayout;
  }

  get config() {
    return this.config$.value;
  }

  set config(config: LayoutConfig) {
    this.config$.next(config);
  }

  showSpin(tip?: string) {
    if (!tip) {
      const quotesData = quotes.articles;
      tip = quotesData[Math.floor(Math.random() * quotesData.length)];
    }

    this.globalSpin$.next({
      visible: true,
      tip
    });
  }

  hideSpin() {
    this.globalSpin$.next({
      visible: false,
      tip: null
    });
  }
}

import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationContext } from 'simplyfire/ngx';

// URL Regex
export const UrlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

// Update router url without reloading
export const updateURL = (newUrl: string) => {
  const injector = ApplicationContext.injector;
  const router = injector.get<Router>(Router);
  const prevUrl = router.url;

  window.history.replaceState({}, '', newUrl);

  return prevUrl;
};

// Update query params without reloading
export const updateQueryParams = (
  queryParams: { [key: string]: string | number },
  queryParamsHandling: 'preserve' | 'merge' | '' = 'merge'
) => {
  const injector = ApplicationContext.injector;
  const router = injector.get<Router>(Router);
  const route = injector.get<ActivatedRoute>(ActivatedRoute);

  return router.navigate([], { relativeTo: route, queryParams });
};

import { Inject, Injectable, InjectionToken } from '@angular/core';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { SearchOptions } from '@algolia/client-search';
import type { RequestOptions } from '@algolia/transporter';
// import { SearchParameters } from 'angular-instantsearch/instantsearch/instantsearch';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { getIndexName } from 'app/shared/utils';

export type AlgoliaSearchConfig = {
  appId: string;
  apiKey: string;
};

export type SearchRequest = {
  indexName: string;
  query: string;
  filter?: string[];
  requestOptions?: { [key: string]: any };
};

export const AlgoliaSearchConfigService = new InjectionToken<AlgoliaSearchConfig>('config');

@Injectable({
  providedIn: 'root'
})
export class AlgoliaSearchService {
  searchClient: SearchClient;
  searchRequestChange$: BehaviorSubject<SearchRequest | undefined>;

  private defaultSearchOptions = {
    hitsPerPage: 10
  };

  get searchRequest(): SearchRequest {
    return this.searchRequestChange$.value;
  }

  set searchRequest(request: SearchRequest) {
    this.searchRequestChange$.next(request);
  }

  constructor(
    @Inject(AlgoliaSearchConfigService) private searchConf: AlgoliaSearchConfig,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const { appId, apiKey } = this.searchConf;

    const algoliaClient = algoliasearch(appId, apiKey);

    // Detecting empty search requests
    // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/angular/
    this.searchClient = {
      ...algoliaClient,
      search: (requests) => {
        // {query}: typeahead search
        // {params.query}: instant search
        if (requests.every(({ params, query }) => !query && !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0
            }))
          } as any);
        }

        return algoliaClient.search(requests);
      }
    };
    this.searchRequestChange$ = new BehaviorSubject(null);
  }

  getIndex(indexName: string) {
    indexName = getIndexName(indexName);
    return this.searchClient.initIndex(indexName);
  }

  saveObject(indexName: string, object: Readonly<Record<string, any>>, requestOptions?: RequestOptions) {
    return this.getIndex(indexName).saveObject(object, requestOptions);
  }

  saveObjects(indexName: string, objects: Readonly<Record<string, any>[]>, requestOptions?: RequestOptions) {
    return this.getIndex(indexName).saveObjects(objects, requestOptions);
  }

  deleteObjects(indexName: string, objectIDs: string[], requestOptions?: RequestOptions) {
    return this.getIndex(indexName).deleteObjects(objectIDs, requestOptions);
  }

  deleteObject(indexName: string, objectID: string, requestOptions?: RequestOptions) {
    return this.getIndex(indexName).deleteObject(objectID, requestOptions);
  }

  search(
    indexName: string,
    query: string,
    requestOptions: RequestOptions & SearchOptions = {},
    searchableAttributes?: any
  ) {
    searchableAttributes = searchableAttributes ?? [];

    const index = this.getIndex(indexName);
    if (searchableAttributes?.length) {
      searchableAttributes = searchableAttributes.map((sa) => {
        return sa === 'content' ? 'description' : sa;
      });
    }

    return index.search(query, {
      ...this.defaultSearchOptions,
      ...requestOptions,
      restrictSearchableAttributes: searchableAttributes
    });
  }
}

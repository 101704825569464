import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule, MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'search-filter',
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    ReactiveFormsModule,
    FormsModule
  ],
  standalone: true,
  templateUrl: './search-filter.component.html'
})
export class SearchFilterComponent implements OnInit {
  @ViewChild('searchFilter') searchFilter: MatSelectionList;

  searchFields = [];
  selectedFields: string[] = [];

  constructor(private dialogRef: MatDialogRef<SearchFilterComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit() {
    if (!this.data) {
      return;
    }

    const { searchableAttrs, selectedAttrs } = this.data;
    this.searchFields = searchableAttrs;
    this.selectedFields = selectedAttrs;
  }

  clearAll() {
    this.selectedFields = [];
  }

  close(data?: string[]) {
    this.dialogRef.close(data);
  }

  saveAndClose() {
    this.close(this.selectedFields);
  }
}

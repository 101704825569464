import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DndDirective } from './components/dnd.directive';
import { ExternalLinkDirective } from './components/external-link.directive';
import { LinkToUserDirective } from './components/link-to-user.directive';
import { UserTooltipDirective } from './components/user-tooltip.directive';
import { PipesModule } from './pipes/pipes.module';

const modules = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MatIconModule, MatButtonModule];

const directives = [DndDirective, ExternalLinkDirective, LinkToUserDirective, UserTooltipDirective];

@NgModule({
  imports: [...modules, ...directives, PipesModule],
  exports: [...modules, ...directives, PipesModule]
})
export class SharedModule {}

import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, first, lastValueFrom } from 'rxjs';
import {
  AuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  authState,
  signInWithEmailAndPassword,
  signInAnonymously,
  signOut,
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
  applyActionCode,
  verifyPasswordResetCode,
  Auth,
  getAdditionalUserInfo,
  EmailAuthProvider,
  signInWithCredential,
  updatePassword,
  linkWithCredential,
  reauthenticateWithPopup
} from '@angular/fire/auth';

import { User as _User } from '@arbitral/common';
import { Objects } from 'app/shared/utils';
import { UserService } from '../user/user.service';
import { NGXLogger } from 'ngx-logger';

type User = Partial<_User> & any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: BehaviorSubject<User>;

  constructor(
    private router: Router,
    @Optional() private auth: Auth,
    private userService: UserService,
    private logger: NGXLogger
  ) {
    this.user$ = new BehaviorSubject(null);
    this.auth = auth;
  }

  set currentUser(user: User) {
    this.user$.next(user);
  }

  get currentUser() {
    return this.user$.value;
  }

  async checkAuthState() {
    if (this.currentUser) {
      return this.currentUser;
    }

    const user = await lastValueFrom(authState(this.auth).pipe(first()));
    if (user && !user.isAnonymous) {
      return (this.currentUser = user);
    }

    await this.signInAnonymously();

    return;
  }

  /**
   *
   * Sign In with Email and Password
   */
  async signInWithEmailAndPassword(email: string, password: string) {
    const { user } = await signInWithEmailAndPassword(this.auth, email, password);

    if (!user.emailVerified) {
      const error: any = {
        code: 'auth/email-not-verified',
        message: `We have sent the verification email to ${user.email}. If you cannot find the email verification mail in the inbox folder, please check the Junk/Spam folder.`
      };
      throw error;
    }

    return (this.currentUser = user);
  }

  /**
   * Sign In Anonymously
   */
  signInAnonymously() {
    return signInAnonymously(this.auth);
  }

  /**
   * Sign in with Provider(Google/Facebook, etc)
   */
  async signInWithProvider(provider: AuthProvider) {
    const credential = await signInWithPopup(this.auth, provider);
    const { user } = credential;
    const { isNewUser } = getAdditionalUserInfo(credential);

    this.currentUser = user;

    return isNewUser ? await this.userService.createUser(user) : user;
  }

  /**
   *
   * Sign Up with Email and Password
   */
  async createUserWithEmail(email: string, password: string, extraInfo: Partial<User> = {}) {
    let { user } = await createUserWithEmailAndPassword(this.auth, email, password);
    const { displayName } = extraInfo;

    if (displayName) {
      await updateProfile(user, { displayName });
      user = Object.assign(user, extraInfo);
    }

    await sendEmailVerification(user);
    await this.userService.createUser(user);
    await this.signOut(false);

    this.router.navigateByUrl(`${pathToMailConfirm}?email=${email}&&mode=verifyEmail`);
  }

  async sendPasswordReset(email: string) {
    await sendPasswordResetEmail(this.auth, email);

    this.router.navigateByUrl(`${pathToMailConfirm}?email=${email}&&mode=resetPassword`);
  }

  confirmPasswordReset(code: string, password: string) {
    return confirmPasswordReset(this.auth, code, password);
  }

  async verifyEmailCode(code: string) {
    await applyActionCode(this.auth, code);
  }

  verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCode(this.auth, code);
  }

  async updateProfile(user: User) {
    const currentUser = this.auth.currentUser;

    if (!currentUser) {
      return;
    }

    const { displayName, photoURL } = user;

    const profileData = {};
    if (displayName) {
      profileData['displayName'] = displayName;
    }

    if (photoURL) {
      profileData['photoURL'] = photoURL;
    }

    if (!Objects.isEmpty(profileData)) {
      await updateProfile(currentUser, profileData);
      this.currentUser = Object.assign(this.currentUser, profileData);
    }

    return this.userService.updateUser(user);
  }

  navigateToLogin(redirectTo?: string) {
    return this.router.navigate(
      [pathToLogIn],
      redirectTo ? { queryParams: { redirectTo: encodeURI(redirectTo) } } : {}
    );
  }

  async signOut(redirect = true) {
    await signOut(this.auth);
    this.currentUser = null;

    if (redirect) {
      await this.navigateToLogin();
    }
  }

  async getLinkedProviders() {
    const user = this.auth.currentUser;
    const { signInProvider } = await user.getIdTokenResult();

    return {
      signInProvider,
      providers: user.providerData.map((p) => p.providerId)
    };
  }

  async reauthenticate(providerId: string, password?: string) {
    const currentUser = this.auth.currentUser;
    if (providerId === 'password') {
      const credential = EmailAuthProvider.credential(currentUser.email, password);
      await signInWithCredential(this.auth, credential);
    } else if (['google.com', 'facebook.com', 'twitter.com'].includes(providerId)) {
      let provider =
        providerId !== 'google.com'
          ? providerId === 'facebook.com'
            ? new FacebookAuthProvider()
            : new TwitterAuthProvider()
          : new GoogleAuthProvider();
      await reauthenticateWithPopup(currentUser, provider);
    }
  }

  async changeOrLinkPasswordAuth(newPassword: string) {
    const prevUser = this.auth.currentUser;

    if (!prevUser.email) {
      return;
    }

    try {
      const hasPasswordAuth = prevUser.providerData.filter((p) => p.providerId === 'password').length > 0;
      if (hasPasswordAuth) {
        await updatePassword(prevUser, newPassword);
      } else {
        const credential = EmailAuthProvider.credential(prevUser.email, newPassword);
        await linkWithCredential(prevUser, credential);
      }
    } catch (e) {
      this.logger.error(e);
    }
  }
}

export const authProviders = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Google: GoogleAuthProvider,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Facebook: FacebookAuthProvider,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Twitter: TwitterAuthProvider
};

export const pathToHome = '/';
export const pathToLogIn = '/auth/login';
export const pathToMailConfirm = '/auth/confirm';

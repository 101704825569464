import { Component, OnInit } from '@angular/core';
import { AppUpdateService } from './services/app-update.service';
import { FuseConfirmationService } from '../@fuse/services/confirmation';
import { ToastService } from './shared/services/toast.service';
import { lastValueFrom, Observer } from 'rxjs';
import { LocalStorageService } from './shared/services/local-storage.service';
import { Constants } from './constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Constants('TUTORIAL_PROMPT_KEY')
  private tutorialPromptKey;

  constructor(
    private appUpdateService: AppUpdateService,
    private fuseConfirmationService: FuseConfirmationService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService
  ) {}
  appUpdateObserver: Observer<void>;

  ngOnInit() {
    this.appUpdateService.initAppUpdateChecker();

    this.appUpdateObserver = {
      next: async () => {
        if (await this.getUpdateConfirmation()) {
          await this.appUpdateService.installUpdate();
          this.toastService.showWithTitle('Updated!', 'Update successful, reloading...', 'success', false, 4500);
          setTimeout(() => {
            window.location.reload();
          }, 4100);
        }
      },
      error: () => {},
      complete: () => {}
    };

    this.appUpdateService.subscribeToAppUpdateEvents(this.appUpdateObserver);

    // Setup tutorial prompt
    const tutorial = this.localStorageService.getData(this.tutorialPromptKey);
    if (!tutorial || tutorial === 'yes') {
      setTimeout(async () => {
        await this.presentTutorial();
      }, 10000);
    }
  }

  ngOnDestroy() {
    this.appUpdateService.unSubscribeToAppUpdateEvents(this.appUpdateObserver);
  }

  async getUpdateConfirmation() {
    const confirmDlg = this.fuseConfirmationService.open({
      title: 'Update Available',
      message: 'A new version of Arbitral is available, update now?',
      icon: {
        name: 'heroicons_outline:question-mark-circle',
        color: 'primary'
      },
      actions: {
        confirm: {
          label: 'Yes',
          color: 'primary'
        },
        cancel: {
          label: 'No'
        }
      }
    });

    const res = await lastValueFrom(confirmDlg.afterClosed());

    return res === 'confirmed';
  }

  async presentTutorial() {
    const content =
      '<div class="tutorial-content">For the basics, watch this short tutorial: ' +
      '<a href="https://vimeo.com/arbitral/tutorial" target="_tutorial">Using Arbitral</a></div>';

    const tutorialDlg = this.fuseConfirmationService.open({
      title: 'New to Arbitral?',
      message: content,
      icon: {
        name: 'heroicons_outline:question-mark-circle',
        color: 'primary'
      },
      actions: {
        confirm: {
          label: 'Remind me Later',
          color: 'primary'
        },
        cancel: {
          label: 'No Need'
        }
      }
    });

    const res = await lastValueFrom(tutorialDlg.afterClosed());

    if (res === 'confirmed') {
      this.localStorageService.saveData(this.tutorialPromptKey, 'yes');
    } else {
      this.localStorageService.saveData(this.tutorialPromptKey, 'no');
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { ModuleWithProviders } from '@angular/core';
import { NgAisModule } from 'angular-instantsearch';
import { AlgoliaSearchConfig, AlgoliaSearchConfigService } from './search.service';
import { SharedModule } from 'app/shared/shared.module';
import { SearchComponent } from 'app/components/search/search.component';
import { SearchSuggestionsComponent } from './search-suggestions.component ';
import { SearchFilterComponent } from './search-filter.component';
@NgModule({
  declarations: [SearchComponent, SearchSuggestionsComponent],
  imports: [
    RouterModule.forChild([]),
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    SharedModule,
    NgAisModule,
    SearchFilterComponent
  ],
  exports: [SearchComponent],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => (): BlockScrollStrategy => overlay.scrollStrategies.block(),
      deps: [Overlay]
    }
  ]
})
export class SearchModule {
  static withConfig(config: AlgoliaSearchConfig): ModuleWithProviders<SearchModule> {
    return {
      ngModule: SearchModule,
      providers: [
        {
          provide: AlgoliaSearchConfigService,
          useValue: config
        }
      ]
    };
  }
}

import { Injectable } from '@angular/core';
import { Database, User, UserStatus } from '@arbitral/common';
import { FirebaseService } from '@arbitral/common/app';
import { AlgoliaSearchService } from 'app/components/search/search.service';
import { QueryBuilder } from 'simplyfire';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _account: User;

  get account() {
    return this._account;
  }

  set account(account: User) {
    this._account = account;
  }

  constructor(private firebaseService: FirebaseService, private searchService: AlgoliaSearchService) {}

  async getUserByHandle(handle: string) {
    if (this._account?.handle === handle) {
      return this._account;
    }

    const qb = new QueryBuilder();
    qb.where('handle', '==', handle);
    qb.limit(1);
    const users = await this.firebaseService.collection(Database.USER, qb);

    return users?.length ? users[0] : null;
  }

  async getUserById(id: string) {
    if (this._account?.id === id) {
      return this._account;
    }

    return this.firebaseService.doc(`${Database.USER}/${id}`);
  }

  getUserContext(id: string) {
    return this.firebaseService.docValueChanges(`${Database.USER_CONTEXT}/${id}`);
  }

  getUserScore(id: string) {
    return this.firebaseService.doc(`${Database.USER_SCORE}/${id}`);
  }

  /**
   * Persist the auth data to firestore
   */
  async createUser(user: User | any) {
    const { uid, email, displayName, photoURL, providerData, providerId, emailVerified } = user;
    const handle = await this.buildUserHandle(displayName);
    const timestamp = this.firebaseService.serverTimestamp;
    const preferences = {
      notifications: {
        alert: {
          debates: true,
          ratings: true,
          mentions: true
        },
        email: {
          debates: false,
          ratings: false,
          mentions: false
        }
      }
    };

    const userData: Partial<User> = {
      email,
      displayName,
      handle,
      providerData,
      preferences,
      status: { type: emailVerified ? 'active' : 'pending', timestamp },
      createdTs: timestamp
    };
    if (providerData?.length) {
      userData.photoURL =
        providerId === 'facebook.com'
          ? `https://graph.facebook.com/${providerData[0].uid}/picture?type=square`
          : photoURL;
    }

    return this.firebaseService.upsert(Database.USER, { ...userData, id: uid }, { merge: true });
  }

  updateUser(user: Partial<User>) {
    return this.firebaseService.upsert(Database.USER, user);
  }

  removeUser(userId: string) {
    return this.firebaseService.delete(`${Database.USER}/${userId}`);
  }

  updateUserStatus(userId: string, status: UserStatus, reason: string = '') {
    const timestamp = this.firebaseService.serverTimestamp;
    return this.firebaseService.update(`${Database.USER}/${userId}`, { status: { type: status, timestamp, reason } });
  }

  uploadPhoto(photo: string) {
    return this.firebaseService.uploadFile(photo, Date.now().toString());
  }

  getAllUsers() {
    return this.firebaseService.collection(Database.USER, null, 0);
  }

  private async buildUserHandle(displayName: string = '') {
    const [firstName, ...restOfName] = displayName.split(' ');
    let handle = (restOfName?.length ? restOfName.pop() + firstName.charAt(0) : firstName).replace(/\W/g, '');
    let handleSuffix = Math.floor(Date.now() / 1000);

    handle = handle ? (handle.length < 4 ? handle + handleSuffix : handle) : 'user' + handleSuffix;

    let maxAttempts = 500;
    while (maxAttempts > 0) {
      try {
        const { hits } = await this.searchService.search(Database.USER, '', {
          restrictSearchableAttributes: [`handle:${handle}`]
        });
        if (!hits.length) {
          return handle;
        }
      } catch (e) {
        return handle;
      }

      handle = handle.replace(handleSuffix.toString(), '') + ++handleSuffix;
      maxAttempts--;
    }

    return handle;
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import _ from 'lodash';

import {
  Article,
  Database,
  Category,
  RssFeedDescriptor,
  arrayToChunks,
  Author,
  Publisher,
  createId
} from '@arbitral/common';
import { QueryBuilder } from 'simplyfire';

import { FirebaseService } from '@arbitral/common/app';
import { Autowire } from 'simplyfire/ngx';
import { HttpsFunctions } from 'app/constants';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  @Autowire()
  firebaseService: FirebaseService;
  articleOpenedEvent: EventEmitter<boolean>;

  protected articles: Article[] = [];

  constructor() {
    this.articleOpenedEvent = new EventEmitter<boolean>();
  }

  /**
   * Get Article Categories
   *
   */
  getCategories() {
    return this.firebaseService.collection<Category>(Database.CATEGORY);
  }

  /**
   * Get RSS Feed Descriptors
   *
   */
  async getDescriptors(categories: string[] = []): Promise<RssFeedDescriptor[]> {
    if (!categories.length) {
      const qb = new QueryBuilder();
      qb.where('enabled', '==', true);

      return this.firebaseService.collection<RssFeedDescriptor>(Database.RSS_FEED_DESCRIPTOR, qb);
    }

    // https://firebase.google.com/docs/firestore/query-data/queries#query_limitations
    const upToLimit = 10;

    const operators = arrayToChunks(categories, upToLimit).map((cats) => {
      const qb = new QueryBuilder();
      qb.where('categories', 'array-contains-any', cats);
      qb.where('enabled', '==', true);

      return this.firebaseService.collection<RssFeedDescriptor>(Database.RSS_FEED_DESCRIPTOR, qb);
    });

    return [].concat.apply([], await Promise.all(operators));
  }

  getRecentArticles(limit = 3) {
    const qb = new QueryBuilder();

    qb.limit(limit);
    qb.orderBy('createdTs', 'desc');
    qb.where('status', 'in', ['active', 'debated']);
    qb.leftJoin('publisherId', Database.PUBLISHER, 'publisher');

    return this.firebaseService.collectionSnapshotChanges(Database.ARTICLE, qb, ['added']);
  }

  getArticlesByUser(uid: string, limit = 3) {
    const qb = new QueryBuilder();
    qb.where('creatorId', '==', uid);
    qb.limit(limit);
    qb.orderBy('createdTs', 'desc');
    qb.leftJoin('id', Database.ARTICLE_DEBATE_CONTEXT, 'context');

    return this.firebaseService.collection(Database.ARTICLE, qb);
  }

  debate(id: string, articleData?: Partial<Article & { author: any; publisher: any }>) {
    return this.firebaseService.callHttpsFunc(HttpsFunctions.DEBATE_ARTICLE, { id, ...articleData });
  }

  getArticleContext(articleId: string) {
    return this.firebaseService.docValueChanges(`${Database.ARTICLE_CONTEXT}/${articleId}`);
  }

  getArticleDebateContext(articleId: string) {
    return this.firebaseService.docValueChanges(`${Database.ARTICLE_DEBATE_CONTEXT}/${articleId}`);
  }

  async getArticleBySlug(slug: string) {
    const article = this.articles.find((d) => d.slug === slug);

    if (article) {
      return article;
    }

    const qb = new QueryBuilder();
    qb.where('slug', '==', slug);

    const articles = await this.getArticles(qb);

    return articles?.length ? articles[0] : null;
  }

  async getArticleById(id: string) {
    const article = this.articles.find((d) => d.id === id);

    if (article) {
      return article;
    }

    return this.firebaseService.doc(`${Database.ARTICLE}/${id}`);
  }

  async updateAuthor(author: Partial<Author>) {
    author.id = author.name.replace(' ', '_').replace('.', '');
    await this.firebaseService.upsert(Database.AUTHOR, author, { merge: true });
    return author.id;
  }

  async createAuthor(authorName) {
    const authorId = authorName.replace(' ', '_').replace('.', '');
    const existingAuthor = await this.firebaseService.doc(`${Database.AUTHOR}/${authorId}`);
    if (!existingAuthor) {
      const author: Author = {
        id: authorName.replace(' ', '_').replace('.', ''),
        name: authorName,
        bias: 0,
        accuracy: 0,
        score: 0
      };
      await this.firebaseService.upsert(Database.AUTHOR, author, { merge: true });
    }

    return authorId;
  }

  async createPublisherFromJson(jsonPublisher: Partial<Publisher>, articleLink) {
    jsonPublisher.id = createId(articleLink);
    const existingPublisher = await this.firebaseService.doc(`${Database.PUBLISHER}/${jsonPublisher.id}`);

    if (!existingPublisher) {
      const articleURL = new URL(articleLink);
      const newPublisher = {
        id: jsonPublisher.id,
        name: jsonPublisher.name,
        link: articleURL.host,
        accuracy: -1,
        bias: -1,
        score: -1,
        totalDebates: 0
      };
      await this.firebaseService.upsert(Database.PUBLISHER, newPublisher, { merge: true });
    }

    return jsonPublisher.id;
  }

  async createPublisher(parsedArticle, publisherName) {
    const pubId = createId(parsedArticle.url);
    const existingPublisher = await this.firebaseService.doc(`${Database.PUBLISHER}/${pubId}`);
    if (!existingPublisher) {
      const articleURL = new URL(parsedArticle.url);
      const publisher = {
        id: pubId,
        name: publisherName,
        link: articleURL.host,
        accuracy: -1,
        bias: -1,
        score: -1,
        totalDebates: 0
      };
      await this.firebaseService.upsert(Database.PUBLISHER, publisher, { merge: true });
    }

    return pubId;
  }

  addArticle(article: Partial<Article>) {
    return this.firebaseService.upsert(Database.ARTICLE, article, { merge: true });
  }

  markArticleAsOpened(articleId: string) {
    localStorage.setItem(articleId, Date.now().toString());

    this.articleOpenedEvent.emit(true);
  }

  getArticleOpenedEventEmitter() {
    return this.articleOpenedEvent;
  }

  isArticleOpened(articleId: string) {
    const item = localStorage.getItem(articleId);
    return item?.length > 0;
  }

  getArticles(qb = new QueryBuilder(), maxAge?: number) {
    qb.leftJoin('publisherId', Database.PUBLISHER, 'publisher');
    qb.leftJoin('authorIds', Database.AUTHOR, 'authors');

    return this.firebaseService.collection(Database.ARTICLE, qb, maxAge);
  }

  async parseArticleFromUrl(link: string) {
    const article: any = await this.firebaseService.callHttpsFunc(HttpsFunctions.PARSE_ARTICLE, { url: link });

    return { ...article, link };
  }
}

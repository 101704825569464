import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  show(
    message: string,
    type: 'success' | 'info' | 'warn' | 'error' | 'custom' = 'custom',
    sticky = false,
    life = 3000
  ) {
    return this.messageService.add({ severity: type, detail: message, life, sticky });
  }

  showWithTitle(
    title: string,
    message: string,
    type: 'success' | 'info' | 'warn' | 'error' | 'custom' = 'custom',
    sticky = false,
    life = 3000
  ) {
    return this.messageService.add({ severity: type, detail: message, summary: title, life, sticky });
  }
}

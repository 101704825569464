import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { User } from '@arbitral/common';
import { UntilDestroy, untilDestroyed } from '@arbitral/common/app';
import { Constants } from 'app/constants';
import { FuseConfigService } from '@fuse/services/config';
import { AppConfig, Scheme } from 'app/core/config/app.config';

@UntilDestroy()
@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'user'
})
export class UserComponent implements OnInit {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;

  @Input()
  user: User;

  @Constants('DEFAULT_IMG_AVATAR')
  defaultAvatar: string;

  appreance = [
    {
      scheme: 'light',
      icon: 'heroicons_outline:sun'
    },
    {
      scheme: 'dark',
      icon: 'heroicons_outline:moon'
    },
    {
      scheme: 'auto',
      icon: 'heroicons_outline:lightning-bolt'
    }
  ];

  config: AppConfig;

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _fuseConfigService: FuseConfigService,
    private authService: AuthService,
    private userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._fuseConfigService.config$.pipe(untilDestroyed(this)).subscribe((config: AppConfig) => {
      this.config = config;
    });
  }

  async signOut() {
    await this.authService.signOut();
  }

  async openProfile() {
    if (!this.user?.handle) {
      return;
    }

    this.userService.account = this.user;
    await this._router.navigateByUrl(`/users/${this.user.handle}`);
  }

  async openSettings() {
    await this._router.navigateByUrl('/settings/account');
  }

  async openChangelog() {
    await this._router.navigateByUrl('/changelog');
  }

  async openAdmin() {
    await this._router.navigateByUrl('/admin');
  }

  setScheme(scheme: Scheme) {
    if (scheme === 'dark') {
      alert('Coming soon!');
      return;
    }
    this._fuseConfigService.config = { scheme };
  }
}

<div class="flex flex-col max-w-240 md:min-w-160 -m-6">
  <!-- Header -->
  <div class="flex flex-0 items-center justify-between h-14 pr-3 pl-4 bg-primary text-on-primary">
    <div class="text-lg font-medium">Custom Search</div>
    <button mat-icon-button (click)="close()" [tabIndex]="-1">
      <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>

  <div class="w-full p-4">
    <p class="text-secondary">Select or deselect fields you want to include in the search.</p>
    <mat-selection-list color="primary" [(ngModel)]="selectedFields">
      <mat-list-option *ngFor="let field of searchFields" [value]="field">
        <span class="capitalize">{{ field }}</span>
      </mat-list-option>
    </mat-selection-list>
  </div>

  <!-- Actions -->
  <div class="flex items-center w-full">
    <div class="sm:ml-auto my-2">
      <button (click)="clearAll()" class="fuse-mat-button w-full sm:w-30 mr-2" mat-button [color]="'primary'">
        <span>Clear</span>
      </button>
      <button (click)="saveAndClose()" class="fuse-mat-button w-full sm:w-30 mr-2" mat-flat-button [color]="'primary'">
        <span>Done</span>
      </button>
    </div>
  </div>
</div>

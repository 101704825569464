import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

import { User } from '@arbitral/common';
import { UserService } from '../user/user.service';
import { ToastService } from 'app/shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthResolver implements Resolve<User> {
  constructor(private authService: AuthService, private userService: UserService, private toastService: ToastService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User> {
    const currentUser = await this.authService.checkAuthState();

    // fetch userInfo in the background to reduce first loading time
    if (currentUser) {
      this.userService.getUserById(currentUser.uid).then(async (userInfo) => {
        // TODO: temporary disable below logic until another parts are completed;
        // const status = userInfo?.status;
        // if (status?.type !== 'active') {
        //   this.toastService.show(
        //     `Sorry your account is ${status?.type || 'under review'}, please contact to administrators.`,
        //     'error',
        //     5000
        //   );
        //   this.authService.currentUser = null;
        //   await this.authService.signOut(false);
        //   await this.authService.signInAnonymously();
        // }

        // if user data is not ready in the firestore, force them to sign out.
        if (!userInfo) {
          await this.authService.signOut(true);
        }

        this.authService.currentUser = Object.assign({}, currentUser, userInfo);
      });
    }

    return;
  }
}

export const authResolver = {
  resolve: {
    auth: AuthResolver
  }
};

import { Routes } from '@angular/router';
import { authResolver } from 'app/core/auth/auth.resolver';

export const pagesRoutes: Routes = [
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then((m) => m.PrivacyModule),
    ...authResolver
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then((m) => m.TermsModule),
    ...authResolver
  },
  {
    path: 'frequently-asked-questions',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    ...authResolver
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
    ...authResolver
  },
  {
    path: 'changelog',
    loadChildren: () => import('./changelog/changelog.module').then((m) => m.ChangelogModule)
  },
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)
  }
];

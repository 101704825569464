<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="bg-card print:hidden border-none"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex items-center justify-center w-full pt-8">
      <!-- Logo -->
      <div class="flex flex-col items-center justify-center">
        <img class="w-16" [src]="appLogo" />
        <p class="mt-2 font-semibold text-xl text-secondary">Arbitral</p>
      </div>
      <!-- Components -->
      <!-- <div class="flex items-center ml-auto">
                <user></user>
            </div> -->
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationContentFooter>
    <!-- Compose button -->
    <button class="w-44 mx-6 mt-4" mat-flat-button [color]="'primary'" (click)="openDebate()" [disabled]="!canDebate">
      <mat-icon
        [svgIcon]="'heroicons_outline:plus'"
        [matBadge]="canDebate && hasDraft ? '!' : ''"
        [matBadgeSize]="'small'"
        [matBadgeColor]="'primary'"
        class="badge-bg-white"
      ></mat-icon>
      <span class="ml-2">Article</span>
    </button>
  </ng-container>

  <ng-container fuseVerticalNavigationFooter>
    <!-- Compose button -->
    <div class="flex flex-0 items-center justify-center h-16 px-4 mt-2 mb-22 md:mb-8 opacity-75">
      <ng-container *ngIf="user$ | async as user; else tplLogin">
        <user [user]="user"></user>
      </ng-container>

      <ng-template #tplLogin>
        <button mat-button class="w-full" (click)="openSignIn()">
          <div class="flex items-center justify-between">
            <mat-icon svgIcon="heroicons_outline:login" class="text-primary"></mat-icon>

            <span class="ml-3">Sign in</span>
          </div>
        </button>
      </ng-template>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 main-wrapper">
  <!-- Content -->
  <!-- <div class="flex flex-col flex-auto fixed mt-14 left-0 w-full md:pl-56 overflow-y-auto main-content"> -->
  <div class="flex flex-col flex-auto" [ngClass]="{ 'progress-spinner-showing': globalSpin.visible }">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <ng-container *ngIf="globalSpin.visible">
    <progress-spinner
      [mode]="'indeterminate'"
      [backdropEnabled]="false"
      [positionGloballyCenter]="true"
      [displayProgressSpinner]="true"
      [tip]="globalSpin.tip"
    ></progress-spinner>
  </ng-container>

  <!-- Footer -->
  <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FilterByPipe } from './filter-by.pipe';
import { OrderByPipe } from './order-by.pipe';
import { SearchByPipe } from './search-by.pipe';
import { UserHandlePipe } from './user-handle.pipe';
import { TruncateImgPipe } from './truncate-img.pipe';
import { TimeAtPipe } from './time-at.pipe';

import { PipesModule as BasePipesModule } from '@arbitral/common/app';

const pipes = [FilterByPipe, OrderByPipe, SearchByPipe, UserHandlePipe, TruncateImgPipe, TimeAtPipe];

@NgModule({
  declarations: [pipes],
  imports: [CommonModule, BasePipesModule],
  exports: [BasePipesModule, pipes],
  providers: [DatePipe]
})
export class PipesModule {}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAt'
})
export class TimeAtPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: Date, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }

    const elapsed =
      new Date().setHours(0, 0, 0, 0) - new Date(value.getFullYear(), value.getMonth(), value.getDate()).getTime();

    return this.datePipe.transform(value, elapsed > 0 ? 'LLL dd' : 'HH:mm');
  }
}

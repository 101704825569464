import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[externalLink]',
  standalone: true
})
export class ExternalLinkDirective {
  @Input()
  externalLink: string;

  @Output()
  linkClickedHandler = new EventEmitter<any>();

  @HostListener('click') onClick() {
    if (!this.externalLink) {
      return;
    }

    this.linkClickedHandler.emit(this.externalLink);
    window.open(this.externalLink, '_blank');
  }
}

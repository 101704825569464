import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@arbitral/common';
import { UserService } from 'app/core/user/user.service';

@Directive({
  selector: '[linkToUser]',
  standalone: true
})
export class LinkToUserDirective {
  @Input('linkToUser')
  account: User;

  constructor(private router: Router, private userService: UserService) {}

  @HostListener('click')
  onClick() {
    const handle = this.account?.handle;

    if (handle) {
      this.userService.account = this.account;
      this.router.navigateByUrl(`/users/${handle}`);
    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import _ from 'lodash';

import { Article, Database } from '@arbitral/common';
import { QueryBuilder } from 'simplyfire';

import { AuthService } from 'app/core/auth/auth.service';
import { ArticleService } from 'app/services/article.service';
import { AppCookies } from 'app/constants';

export const debateQuickFilters = {
  popularity: 'Popularity',
  yours: 'Your Debates',
  newest: 'Newest Debates'
};

export type DebatesFilter = { [key: string]: any };
export type DebateQuickFilter = keyof typeof debateQuickFilters;

@Injectable({
  providedIn: 'root'
})
export class DebateService extends ArticleService implements Resolve<Article> {
  get draft() {
    const item = localStorage.getItem(AppCookies.DRAFT_DEBATE);
    return item && JSON.parse(item);
  }

  set draft(draft: any) {
    localStorage.setItem(AppCookies.DRAFT_DEBATE, JSON.stringify(draft));
  }

  constructor(private authService: AuthService) {
    super();
  }

  async resolve(route: ActivatedRouteSnapshot): Promise<Article> {
    const { slug } = route.params;
    return await this.getDebateBySlug(slug);
  }

  /**
   * Get (newest|popularity|your)debates
   *
   * @param limit // total feeds to list, required to reduce read operations of firebase
   */
  async getDebatesWithCursor(
    quickFilter: DebateQuickFilter,
    newsCategories: string[],
    filter: DebatesFilter = { limit: 3 }
  ) {
    const { limit, startAfter, reload } = filter;

    const qb = new QueryBuilder();

    if (limit) {
      qb.limit(limit);
    }

    if (startAfter) {
      qb.startAfter(startAfter);
    }

    if (reload || !startAfter) {
      this.articles = [];
    }

    switch (quickFilter) {
      case 'popularity':
        qb.orderBy('popularity', 'desc');
        break;
      case 'newest':
        qb.orderBy('debatedTs', 'desc');
        break;
      case 'yours':
        qb.where('debaterId', '==', this.authService.currentUser.uid);
        qb.orderBy('debatedTs', 'desc');
        break;
    }

    qb.where('status', '==', 'debated');
    qb.leftJoin('id', Database.ARTICLE_DEBATE_CONTEXT, 'context');
    qb.leftJoin('debaterId', Database.USER, 'debater');

    if (newsCategories.length && newsCategories.length < 10) {
      qb.where('categories', 'array-contains-any', newsCategories);
    }

    const articles = await this.getArticles(qb, 0);

    // cursor is required for the infinite-scrolling
    let cursor: string;
    if (articles.length) {
      cursor = _.last(articles).id;
      this.articles = [...this.articles, ...articles];
    }

    return { articles: this.articles, cursor };
  }

  async getDebateBySlug(slug: string) {
    const article = this.articles.find((d) => d.slug === slug);

    if (article) {
      return article;
    }

    const qb = new QueryBuilder();
    qb.where('slug', '==', slug);
    qb.leftJoin('id', Database.ARTICLE_DEBATE_CONTEXT, 'context');
    qb.leftJoin('debaterId', Database.USER, 'debater');

    const articles = await this.getArticles(qb);

    return articles?.length ? articles[0] : null;
  }

  getDebatesByUser(uid: string, limit = 3) {
    const qb = new QueryBuilder();

    qb.where('debaterId', '==', uid);
    qb.limit(limit);
    qb.orderBy('debatedTs', 'desc');
    qb.leftJoin('id', Database.ARTICLE_DEBATE_CONTEXT, 'context');

    return this.getArticles(qb);
  }

  getDebatesByPopularity(limit = 5) {
    const qb = new QueryBuilder();

    qb.where('popularity', '>', 0);
    qb.limit(limit);
    qb.orderBy('popularity', 'desc');
    qb.leftJoin('id', Database.ARTICLE_DEBATE_CONTEXT, 'context');

    return this.firebaseService.collectionSnapshotChanges(Database.ARTICLE, qb, ['added', 'modified']);
  }
}

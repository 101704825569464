import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, concat, first, interval, Observable, Observer, Subject } from 'rxjs';
import { isNumber } from 'lodash';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  appUpdateObservable = new Subject<void>();

  constructor(private appReference: ApplicationRef, private swUpdate: SwUpdate, private logger: NGXLogger) {
    swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          this.logger.info(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          this.logger.info(`Current app version: ${evt.currentVersion.hash}`);
          this.logger.info(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.appUpdateObservable.next();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          this.logger.info(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  initAppUpdateChecker() {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appReference.isStable.pipe(first((isStable) => isStable === true));
    const updateCheckInterval$ = interval(6 * 60 * 60 * 1000);
    const updateCheckObservable$ = concat(appIsStable$, updateCheckInterval$);

    updateCheckObservable$.subscribe(async (val) => {
      if (isNumber(val)) {
        try {
          const updateFound = await this.swUpdate.checkForUpdate();
          if (updateFound) {
            this.appUpdateObservable.next();
          }
        } catch (err) {
          this.logger.error('Failed to check for updates:', err);
        }
      }
    });
  }

  async installUpdate() {
    const updateFound = await this.swUpdate.checkForUpdate();
    if (updateFound) {
      await this.swUpdate.activateUpdate();
    }
  }

  subscribeToAppUpdateEvents(observer: Observer<any>) {
    return this.appUpdateObservable.subscribe(observer);
  }

  unSubscribeToAppUpdateEvents(observer: Observer<any>) {
    return this.appUpdateObservable.unsubscribe();
  }
}

<ng-template #progressSpinnerRef>
  <!-- <mat-progress-spinner [color]="color" [diameter]="diameter" [mode]="mode" [strokeWidth]="strokeWidth" [value]="value">
	</mat-progress-spinner> -->
  <div class="flex flex-col text-center -mt-8">
    <div style="color: #f59e0b" class="la-timer la-2x mx-auto">
      <div></div>
    </div>
    <p class="mt-3" *ngIf="tip">
      {{ tip }}
    </p>
  </div>
</ng-template>

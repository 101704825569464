import { enableProdMode, inject } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import 'quill-mention';
import * as Emoji from 'quill-emoji';

declare const Quill: any;
Quill.register('modules/emoji', Emoji);

if (environment.production) {
  enableProdMode();

  const { dsn, tracingOrigins } = environment.sentryConf;

  Sentry.init({
    dsn,
    environment: environment.target,
    integrations: [
      new BrowserTracing({
        tracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

const constants = {
  APP_LOGO: 'assets/images/logo/logo.svg',
  DEFAULT_IMG_AVATAR: 'assets/images/avatars/profile-alt.jpg',
  DEFAULT_IMG_PUBLISHER: 'assets/images/default-publisher.png',
  DEFAULT_IMG_EMPTY: 'assets/images/empty.png',
  SEARCH_MIN_CHARACTERS: 4,
  QUERY_PARAM_OF_DEBATE_FILTERS: 'quickFilter',
  QUERY_PARAM_OF_ARTICLE_SEARCH: 'search',
  QUERY_PARAM_OF_ARTICLE_CATEGORIES: 'categories',
  QUERY_PARAM_OF_ARTICLE_PUBLISHERS: 'publishers',
  ANONYMOUS_DATA_LIMIT: 6,
  TUTORIAL_PROMPT_KEY: 'show-tutorial'
};

export const HttpsFunctions = {
  PARSE_ARTICLE: 'requestParseArticle',
  PARSE_RSS_FEED: 'requestParseRssFeed',
  DEBATE_ARTICLE: 'requestDebateArticle',
  SEND_NOTIFICATION: 'requestSendNotification'
};

export const AppCookies = {
  DRAFT_DEBATE: 'DRAFT'
};

export const enum AnalyticsEventName {
  VIEW_DEBATES = 'view_debates',
  VIEW_LEADERBOARD = 'view_leaderboard',
  VIEW_NEWS_FEED = 'view_news_feed',
  VIEW_DEBATE = 'view_debate',
  VIEW_DEBATE_DISCUSSION = 'view_debate_discussion',
  VIEW_NOTIFICATIONS = 'view_notifications',
  SEARCH_DEBATES = 'search_debates',
  SEARCH_NEWS_FEED = 'search_news_feed',
  START_DEBATE = 'start_debate',
  ADD_DEBATE_RATING = 'add_debate_rating',
  ADD_RATING_COMMENT = 'add_rating_comment',
  ADD_DISCUSSION_COMMENT = 'add_discussion_comment',
  EXTERNAL_ARTICLE_IMPORT_FAILED = 'external_article_import_failed',
  EXTERNAL_ARTICLE_DEBATE_FAILED = 'external_article_debate_failed'
}

export const Constants =
  (cstName?: keyof typeof constants) =>
  (target: any, property: string): any => {
    target[property] = constants[cstName || property];
    return target;
  };

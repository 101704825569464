<!-- Button -->
<button mat-button [matMenuTriggerFor]="userActions">
  <div class="flex items-center justify-between">
    <div class="flex items-center mr-4" *ngIf="user">
      <img class="w-9 h-9 rounded-full" [src]="user.photoURL || defaultAvatar" />

      <span
        class="absolute left-7 top-7 bottom-0 w-2 h-2 rounded-full"
        [ngClass]="{
          'mr-px mb-px': !showAvatar || !user.avatar,
          'bg-green-500': user.status !== 'online',
          'bg-amber-500': user.status === 'away',
          'bg-red-500': user.status === 'busy',
          'bg-gray-400': user.status === 'not-visible'
        }"
      ></span>
      <span class="flex flex-col leading-normal pl-3 items-start w-36 max-w-36 truncate overflow-ellipsis">
        <span class="">{{ user.displayName }}</span>
        <!-- <span class="mt-1.5 text-secondary text-sm">@stevehines18</span> -->
      </span>
    </div>

    <mat-icon svgIcon="heroicons_outline:dots-horizontal"></mat-icon>
  </div>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{ user?.email }}</span>
    </span>
  </button>
  <ng-container *ngIf="user?.updatedTs">
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="openProfile()">
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      <span>Profile</span>
    </button>
    <button mat-menu-item (click)="openSettings()">
      <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
      <span>Settings</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="userStatus" class="hidden">
      <mat-icon [svgIcon]="'heroicons_outline:color-swatch'"></mat-icon>
      <span>Appreance</span>
    </button>
    <button mat-menu-item *ngIf="user.isAdmin" (click)="openAdmin()">
      <mat-icon [svgIcon]="'heroicons_outline:server'"></mat-icon>
      <span>Go admin</span>
    </button>
    <button mat-menu-item (click)="openChangelog()">
      <mat-icon [svgIcon]="'heroicons_outline:speakerphone'"></mat-icon>
      <span>Changelog</span>
    </button>
  </ng-container>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <ng-container *ngFor="let a of appreance">
    <button mat-menu-item [class.text-primary]="config.scheme === a.scheme" (click)="setScheme(a.scheme)">
      <mat-icon class="icon-size-5" [class.text-primary]="config.scheme === a.scheme" [svgIcon]="a.icon"></mat-icon>
      <span class="text-capitalize">{{ a.scheme }}</span>
    </button>
  </ng-container>
</mat-menu>

import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthResolver } from './core/auth/auth.resolver';
import { pagesRoutes } from './modules/pages/pages.routing';

export const appRoutes: Route[] = [
  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      auth: AuthResolver
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'debates' },
      {
        path: 'debates',
        loadChildren: () => import('./modules/debates/debates.module').then((m) => m.DebatesModule)
      },
      {
        path: 'news',
        loadChildren: () => import('./modules/news/news.module').then((m) => m.NewsModule)
      },
      {
        path: 'leaderboards',
        loadChildren: () => import('./modules/leaderboards/leaderboards.module').then((m) => m.LeaderboardsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/notifications.module').then((m) => m.NotificationsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./modules/searches/searches.module').then((m) => m.SearchesModule)
      }
    ]
  },
  {
    path: 'admin',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    resolve: {
      auth: AuthResolver
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule)
      }
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
      },
      ...pagesRoutes
    ]
  }
];

import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule {
  /**
   * Constructor
   */
  constructor(private _domSanitizer: DomSanitizer, private _matIconRegistry: MatIconRegistry) {
    // Register icon sets
    this._matIconRegistry.addSvgIconSet(
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'iconsmind',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
    );

    this._matIconRegistry.addSvgIcon(
      'si-facebook',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/si-facebook.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'si-google',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/si-google.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'si-twitter',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/si-twitter.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'si-x',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/si-x.svg')
    );
  }
}

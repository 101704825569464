import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { User } from '@arbitral/common';
import { shortNumber, UntilDestroy, untilDestroyed } from '@arbitral/common/app';
import { LayoutService } from 'app/layout/layout.service';
import { defaultNavigation } from 'app/layout/navigation';
import { AuthService, pathToLogIn } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { BehaviorSubject, EMPTY, switchMap } from 'rxjs';
import { Constants } from 'app/constants';
import { DebateService } from 'app/modules/debates/debate.service';
import { VersionService } from '../../../services/version.service';

@UntilDestroy()
@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  styleUrls: ['./classy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, AfterViewInit {
  @Constants('APP_LOGO')
  appLogo: string;

  @Constants('SEARCH_MIN_CHARACTERS')
  searchMinCharacters: number;

  isScreenSmall: boolean;

  navigation = defaultNavigation;

  globalSpin: {
    visible: boolean;
    tip?: string;
  };

  user$: BehaviorSubject<User>;

  canDebate: boolean = false;
  hasDraft: boolean = false;
  appVersion: string;

  private readonly pathToDebate = 'debates/import';

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private authService: AuthService,
    private userService: UserService,
    private layoutService: LayoutService,
    private debateService: DebateService,
    private versionService: VersionService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.appVersion = this.versionService.getAppVersion();

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$.pipe(untilDestroyed(this)).subscribe(({ matchingAliases }) => {
      // Check if the screen is small
      this.isScreenSmall = !matchingAliases.includes('lg');
    });

    this.layoutService.globalSpin$.pipe(untilDestroyed(this)).subscribe((spin) => {
      this.globalSpin = spin;
    });

    this.user$ = this.authService.user$;

    this._router.events.pipe(untilDestroyed(this)).subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.updateActiveItemByPath(evt.url);
      }
    });

    setTimeout(() => {
      this.updateActiveItemByPath(this._router.url);
    });
  }

  ngAfterViewInit() {
    const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
    if (!navComponent) {
      return;
    }

    const item = this._fuseNavigationService.getItem('app.notifications', navComponent.navigation);

    this.user$
      .pipe(
        switchMap((user) => {
          navComponent.refresh();

          return user?.updatedTs ? this.userService.getUserContext(user.uid) : EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((context) => {
        const unread = context?.unreadNotifications ?? 0;
        item.badge =
          unread > 0
            ? {
                title: shortNumber(unread).toString(),
                classes: 'min-w-6 bg-primary-400 text-dark rounded-md px-1'
              }
            : null;
        navComponent.refresh();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string) {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  openDebate() {
    if (!this.user$.value) {
      this._router.navigate([pathToLogIn], { queryParams: { redirectTo: this.pathToDebate } });
      return;
    }

    this._router.navigateByUrl(this.pathToDebate);
  }

  openSignIn() {
    const url = this._router.url.replace(/^\/|\/$/g, '');
    this._router.navigate([pathToLogIn], { queryParams: { redirectTo: url } });
  }

  private updateActiveItemByPath(path: string) {
    const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
    if (!navComponent) {
      return;
    }

    this.canDebate = !this._router.url.replace(/^\/|\/$/g, '').includes(this.pathToDebate);
    this.hasDraft = !!this.debateService.draft;

    const key = `app.${path.split('?')[0].replace(/^\/+/, '').replace(/\/+$/, '').replace('/', '.')}`;
  }
}

import { Injectable, VERSION } from '@angular/core';

// @ts-ignore
import { default as pj } from '/package.json';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private readonly appVersion: string;
  private readonly ngVersion: string;

  constructor() {
    this.appVersion = pj.version;
    this.ngVersion = VERSION.full;
  }

  getAppVersion(): string {
    return this.appVersion;
  }

  isAppBeta(): boolean {
    return this.appVersion?.toLowerCase().includes('beta');
  }

  getAngularVersion(): string {
    return this.ngVersion;
  }
}
